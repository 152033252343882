import React from 'react';
import { Link, graphql } from 'gatsby';
import Helmet from 'react-helmet';
import Layout from '../../layouts/index';

export const pageQuery = graphql`
  {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fileAbsolutePath: { regex: "//news//" } }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "YYYY年MM月DD日")
            title
          }
        }
      }
    }
  }
`;

const NewsContent = ({ data }) => {
  const siteTitle = data.site.siteMetadata.title;
  const posts = data.allMarkdownRemark.edges;

  return (
    <div className="container sub-content" style={{ minHeight: 600 }}>
      <Helmet title={`お知らせ一覧 | ${siteTitle}`} />
      <h1>お知らせ一覧</h1>
      <div>
        {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug;
          return (
            <div key={node.fields.slug} style={{ marginBottom: 30 }}>
              <h3>
                <Link
                  style={{ boxShadow: 'none', color: '#2d8190' }}
                  to={node.fields.slug}
                >
                  {title}
                </Link>
              </h3>
              <small>{node.frontmatter.date}</small>
              <p dangerouslySetInnerHTML={{ __html: node.excerpt }} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

const News = (props) => (
  <Layout>
    <NewsContent {...props} />
  </Layout>
);

export default News;
